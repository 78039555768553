





































import {Component, Vue, Prop} from 'vue-property-decorator';
import {Permission} from '@/misc/enums/permission.enum';

@Component({})
export default class ActionMenu extends Vue {
  @Prop({required: true})
  public item!: any;

  @Prop({default: true})
  public deletable?: boolean;

  @Prop({required: true})
  public updatePermission!: Permission;

  @Prop({required: true})
  private deletePermission!: Permission;

  private onEditClick() {
    this.$emit('edit', this.item);
  }

  private onDeleteClick() {
    this.$emit('delete', this.item);
  }
}
